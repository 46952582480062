<template>
  <div class="section">
    <div class="transaction__filter--container d-block bg-white py-2">
      <b-row class="search__export align-items-center mx-0 justify-content-between">
        <b-col cols="6">
          <div class="d-flex pl-2">
            <b-img class="filter-image" :class="filter.sort_type == 'asc' ? 'rotate-180' : ''"
              :src="require('@/assets/images/icons/Icon-order-list-down.svg')" alt="Icon-order-list-down" role="button"
              @click="filter.sort_type == 'asc' ? filter.sort_type = 'desc' : filter.sort_type = 'asc'" />
            <div class="search__input w-100 pl-2 pr-0 mr-0">
              <b-input-group>
                <b-form-input v-model="filter.search" placeholder="Cari nama kategori" />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </b-col>
        <b-col cols="2" class="">
          <div>
            <b-button class="py-1 border-8 btn-block" @click="$bvModal.show('modal-item')">
              Tambah Kategori
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <vue-good-table :columns="columns" :rows="items" :fixed-header="false" :sort-options="{
      enabled: false
    }" :pagination-options="{
      enabled: false,
    }">
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.field == 'action'" />
      </template>
      <template slot="table-row" slot-scope="props">

        <!-- Column: Nama Barang -->
        <span v-if="props.column.field === 'name'">
          <b-img width="45" height="45" class="border-8 mr-1" :src="props.row.logo_url" />
          <span>{{ props.row.name }}</span>
        </span>

        <span v-else-if="props.column.field === 'parent'">
          {{ props.row.parent ? props.row.parent.name : '-' }}
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span v-if="props.row.merchant_id != null">
            <div class="d-flex">
              <b-button class="mr-1 px-1 border-0 bg-pink" @click="deleteData(props.row.uuid)">
                <feather-icon size="18" class="text-danger" icon="Trash2Icon" />
              </b-button>
              <b-button class="bg-white" @click="editItem(props.row)">
                <b-img :src="require('@/assets/images/icons/Process2.svg')" /> Ubah
              </b-button>
            </div>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <!-- Pagination -->
    <div v-if="items" class="d-flex justify-content-between align-items-center flex-wrap bg-white border"
      style="padding: 0.8rem;">
      <div class="d-flex align-items-center">
        <b-form-select v-model="filter.per_page" :options="['10', '15', '20']" class="mx-1" />
        <span class="text-nowrap ">
          Tampilkan 1 ke {{ filter.per_page }} dari {{ rows.total }} kategori.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination class="mb-0" :data="rows" :limit="4" align="right" @pagination-change-page="getData" />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import {
  BRow, BCol, BFormSelect, VBToggle, BFormInput, BInputGroup, BInputGroupAppend, BImg, BButton,
} from 'bootstrap-vue'

// import ModalAddProductParent from '@/components/MasterProduct/Modal/ModalAddProductParent.vue'
import axios from 'axios'
import { VueGoodTable } from 'vue-good-table'
import FileSaver from 'file-saver'

export default {
  components: {
    VueGoodTable,
    BFormSelect,
    // ModalAddProductParent,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    typeTable: {
      type: String,
    },
    result: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: '',
    },
    deleteData: {
      type: Function,
    },
    getData: {
      type: Function,
    },
    createItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    editId: {
      type: String,
    },
  },
  data() {
    return {
      progressValue: 35,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'ID',
          field: 'formatted_id',
        },
        {
          label: 'Nama Kategori 3',
          field: 'name',
        },
        {
          label: 'Nama Kategori 2',
          field: 'parent',
        },
        {
          label: 'Deskripsi Kategori 3',
          field: 'description',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: {},
      items: [],
      search: '',
      filter: {
        search: '',
        sort_type: 'asc',
        per_page: 10,
      },
      isLoadingImport: false,
      importPayload: {
        file: null,
      },
    }
  },
  watch: {
    result(value) {
      this.rows = value.data
      this.items = value.data.data
    },
    filter: {
      handler(value) {
        this.$emit('filter', value)
      },
      deep: true,
    },
  },
  methods: {
    exportData(type = 'excel') {
      const context = this
      const axiosSource = axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel, msg: 'Loading...' }

      this.$http.get('/item/export/merchant', {
        responseType: type == 'view' ? '' : 'blob',
        // params: this.filter,
        cancelToken: axiosSource.token,
      }).then(response => {
        var timestamp = Math.floor(Date.now() / 1000)
        if (type == 'view') {
          context.contentHtml = response.data.data.html
        } else {
          var timestamp = Math.floor(Date.now() / 1000)
          if (type == 'excel') {
            var fileName = `export-${timestamp}.xlsx`
            FileSaver.saveAs(response.data, fileName)
          }

          if (type == 'pdf') {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            var fileName = `export-${timestamp}.pdf`
            FileSaver.saveAs(blob, fileName)
          }
        }

        context.isLoading = false
      }).catch(error => {
        context.isLoading = false

        if (error.response.data.meta.messages.length > 0) {
          errorNotification(this, 'Oops!', error.response.data.meta.messages)
        }
      })
    },
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

.vgt-responsive {
  height: calc(100vh - 235px) !important;
  background-color: #fff;
}
</style>
